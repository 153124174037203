@font-face {
	font-family: "IBM";
	src: url("#{$url-font}/IBM/ibm.woff2") format("woff2");
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "NAMU";
	src: url("#{$url-font}/NAMU/NAMU-1930.woff2") format("woff2");
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@import "../../assets/style/vars";

.top {
	width: 100%;

	&__slider {
		position: relative;
	}

	&__wrap {
		position: absolute;
		z-index: 2;
		bottom: 8.91vw;
		left: 4.2vw;

		@media (max-width: $sm) {
			bottom: 89px;
			left: 24px;
		}

		@media (max-width: $xs) {
			bottom: 113px;
			left: 12px;
		}
	}

	&__location {
		position: absolute;
		z-index: 2;
		bottom: 8.91vw;
		right: 4.2vw;

		span {
			position: relative;
			margin-right: 72px;
			color: #fff;

			&::after {
				content: "";
				position: absolute;
				bottom: 0.65vw;
				right: -16px;
				transform: translateX(100%);
				width: 40px;
				height: 1px;
				background-color: #fff;
			}

			&:last-child {
				margin-right: 0;

				&::after {
					display: none;
				}
			}
		}

		@media (max-width: $sm) {
			bottom: 89px;
			right: 24px;

			span {
				margin-right: 14px;

				&:after {
					bottom: 9px;
					right: -3px;
					width: 8px;
				}
			}
		}

		@media (max-width: $xs) {
			right: auto;
			bottom: 65px;
			left: 12px;
		}
	}

	&__lucky {
		margin-bottom: 1.57vw;
		display: block;
		width: 28.37vw;
		height: auto;

		@media (max-width: $sm) {
			width: 371px;
		}

		@media (max-width: $xs) {
			width: 267px;
		}
	}

	&__descr {
		margin-bottom: 0.78vw;
		display: block;
		letter-spacing: 0.005em;
		color: #fff;
		width: 25vw;

		@media (max-width: $sm) {
			width: 297px;
		}
	}

	&__law {
		display: block;
		letter-spacing: -0.02em;
		text-transform: uppercase;
		color: #ffffff;
		opacity: 0.5;
	}
}

.slider {
	&_ready {
		&::before {
			content: "";
			position: absolute;
			z-index: 3;
			bottom: 0;
			left: 0;
			transform: scaleX(0);
			transform-origin: left;
			display: block;
			width: 100%;
			height: 4px;
			background-color: $red;
			animation: progress 10s linear infinite;
		}
	}

	&__slide {
		position: relative;
	}

	.slide__bannerContainer {
		color: $white;
		z-index: 3;
		position: absolute;
		top: calc(4.8vw + 4.2vw);
		right: 4.2vw;
		padding: 1.5vw;
		border-radius: 10px;
		width: min-content;
		min-width: 20.7vw;
		max-width: calc(100% - 24px);
		display: flex;
		flex-direction: column;

		@media (max-width: $sm) {
			min-width: 268px;
			top: calc(48px + 24px);
			right: 24px;
			padding: 20px;
		}

		@media (max-width: $xs) {
			min-width: 256px;
			top: calc(48px + 12px);
			right: 12px;
			padding: 13px;
			max-width: calc(100% - 24px);
		}

		.slide__bannerTitle {
			font-family: "IBM", sans-serif;
			font-size: 1vw;
			font-style: normal;
			font-weight: 400;
			line-height: 148%;

			@media (max-width: $sm) {
				font-size: 16px;
			}
		}

		.slide__bannerDesc {
			font-size: 1.5vw;
			font-style: normal;
			font-weight: 600;
			line-height: 120%;

			@media (max-width: $sm) {
				font-size: 20px;
			}
		}

		.slide__bannerButtons {
			&:only-child {
				margin-top: 0;
			}

			margin-top: 1.4vw;
			display: flex;
			gap: 0.7vw;

			@media (max-width: $sm) {
				margin-top: 20px;
				gap: 8px;
			}

			@media (max-width: $xs) {
				margin-top: 12px;
			}

			.slide__bannerButtonPrimary,
			.slide__bannerButtonSecondary {
				display: flex;
				align-items: center;
				justify-content: center;
				text-decoration: none;
				text-align: center;
				font-family: "NAMU", sans-serif;
				font-size: 0.8vw;
				font-style: normal;
				font-weight: 600;
				line-height: 137.5%;
				padding: 0.8vw 1.66vw;
				border-radius: 5px;
				transition: color 0.3s ease, background-color 0.3s ease;
				flex-grow: 1;
				flex-shrink: 1;
				flex-basis: 50%;
				max-width: 50%;
				min-width: 0;

				@media (max-width: $sm) {
					font-size: 14px;
					padding: 9px 20px;
				}
			}

			.slide__bannerButtonPrimary {
				color: $red;
				background-color: $white;

				&:hover {
					color: $white;
					background-color: $red;
				}
			}
			.slide__bannerButtonSecondary {
				color: $white;
				background-color: transparent;
				border: 1px solid $white;

				&:hover {
					color: $red;
					background-color: #f9d2cc;
					border-color: #f9d2cc;
				}
			}
		}
	}
}

@keyframes progress {
	0% {
		transform: scaleX(0);
	}
	100% {
		transform: scale(1);
	}
}

.slide {
	$p: &;
	width: 100%;
	height: calc(var(--vh, 1vh) * 100);
	overflow: hidden;

	&__img {
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: 50% 50%;
	}

	&__bg {
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		display: block;
		width: 100%;
		height: 100%;
		background-color: rgba(#000, 0.2);
	}
}

.slider_ready {
	.slide {
		&__img {
			animation: zoom 10s linear infinite alternate;
		}
	}
}

@keyframes zoom {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(1.75);
	}
}

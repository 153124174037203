$url-font: "../fonts";

$white: #fff;
$blue: #29295e;
$text: #333;
$gray: #ccc;
$violet: #ba99c6;
$green: #45b188;
$yellow: #d7be74;
$burgundy: #40002e;
$bg: #e5e5e5;
$bg2: #c4c4c4;
$red: #ec6a55;
$red-hover: #ce4c37;
$red2: #f0a18b;
$red3: #fdf0ee;

$md: 1279.98px;
$sm: 1023.98px;
$xs: 743.98px;

@import "~sass-snippets/scss/snippets";

@import "../../assets/style/vars";

.popupThx {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 384px;
	text-align: center;

	&__icon {
		display: block;
		width: 90px;
		height: 90px;
		margin-bottom: 24px;
	}

	&__desc {
		margin-bottom: 16px;
		font-family: "NAMU", sans-serif;
		font-size: 30px;
		line-height: 36px;
		font-weight: 600;
		letter-spacing: 0.005em;
		color: $text;
	}

	&__desc2 {
		font-family: "IBM", sans-serif;
		font-size: 18px;
		line-height: 24px;
		font-weight: 400;
		color: $gray;
	}
}

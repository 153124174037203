@import "../../assets/style/vars";

.popup {
	$p: &;
	z-index: 10;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 0;
	transition: opacity 0.3s ease;

	&,
	&__bg {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: calc(var(--vh, 1vh) * 100);
	}

	&__bg {
		z-index: 0;
		background-color: rgba(#000, 0.5);
	}

	&__wrap {
		position: relative;
		width: 610px;
		max-height: calc(var(--vh, 1vh) * 100);
		padding: 32px;
		overflow-y: auto;
		background-color: $white;

		@media (max-width: $xs) {
			width: 100%;
			padding: 24px 12px;
		}

		&::before {
			content: "";
			position: absolute;
			z-index: 3;
			top: 50%;
			right: 32px;
			transform: translateY(-50%);
			display: block;
			width: 56px;
			height: 56px;
			border-radius: 50%;
			background-color: rgba($red, 0.1);
			pointer-events: none;
		}
	}

	&__close {
		position: absolute;
		z-index: 3;
		top: 39px;
		right: 32px;
		display: inline-block;
		width: 29px;
		height: 29px;
		padding: 0;
		background-color: transparent;
		border: none;

		@media (max-width: $xs) {
			top: 24px;
			right: 20px;
		}

		&::before,
		&::after {
			content: "";
			position: absolute;
			top: 50%;
			left: 50%;
			display: inline-block;
			width: 100%;
			height: 1px;
			background-color: $gray;
			transition: background-color 0.2s ease;
		}

		&::before {
			transform: translate(-50%, -50%) rotate(45deg);
		}

		&::after {
			transform: translate(-50%, -50%) rotate(-45deg);
		}

		&:hover,
		&:focus,
		&:active {
			&::before,
			&::after {
				background-color: $red;
			}
		}
	}

	&__decorIcon {
		position: absolute;
		z-index: 0;
		top: 0;
		left: 0;
		display: block;
		width: 201px;
		height: auto;
		pointer-events: none;
	}

	&_visible {
		opacity: 1;
	}

	&_howToBuy {
		#{$p}__wrap {
			width: 38.8vw;
			min-width: 740px;

			@media (max-width: $sm) {
				width: 100%;
				min-width: 100%;
			}
		}
	}
}
